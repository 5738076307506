export enum WaffleFlags {
  UnitLocationsFromTickService = '3D_unit_location_data_from_tickservice',
  IsLiveModeEnabled = 'is_3D_live_mode_enabled',
  IsOddjobEnabled = 'oddjob',
  IsMoneyPennyEnabled = 'is_moneypenny_released',
  IsSurfaceToModelComparisonEnabled = 'surface_to_model_comparison',
  IsEditedAndDeletedMeasuredPointsEnabled = 'edited_and_deleted_measured_points_handling',
  ShowSurfacelogDeltaHeightLegendConfigurationUi2 = 'show_surfacelog_delta_height_legend_configuration_ui2',
  CompressedSignalData = 'compressed_signal_data',
  ShowSurfacelogVolumeCalculations = 'show_surfacelog_volume_calculations',
  FilteringSurfacesByPointCodes = 'filtering_surfaces_by_point_codes',
  ShowJobsInConX = 'show_jobs_in_conx'
}