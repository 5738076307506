<template>
  <div id="app">
    <!-- cesium map is loaded here -->
    <div id="map">
      <cx-map-tooltip
        :entity="hoveredEntity"
        :tooltipPosition="hoverTooltipPosition"
        id="hoverTooltip"
        v-if="hoveredEntity && hoverTooltipPosition"/>
      <cx-map-tooltip
        :entity="selectedEntity"
        :tooltipPosition="selectTooltipPosition"
        id="selectTooltip"
        v-if="selectTooltipPosition"/>
      <cx-header/>
      <cx-object-explorer/>
      <cx-details-panel/>
      <cx-filter-bar/>
      <cx-filter-panel/>
      <cx-grid-popup/>
      <cx-licence-popup popupId="popup-cx-licence"/>
      <cx-alert
        v-if="alert"
        v-model:isOpened="isAlertOpened"
        :title="alert.title"
        :subtitle="alert.subtitle"
        :messages="alert.messages"
        :canBeClosed="alert.closeable"/>
      <cx-attribution/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxLicencePopup from '@/app/shell/CxLicencePopup';
import CxHeader from './CxHeader';
import CxObjectExplorer from '@/app/objectExplorer/CxObjectExplorer';
import CxFilterBar from './CxFilterBar';
import CxFilterPanel from './CxFilterPanel';
import CxDetailsPanel from './CxDetailsPanel';
import CxMapTooltip from '@/app/map/CxMapTooltip';
import CxAlert from '@/components/cxAlert/CxAlert';
import CxAttribution from '@/app/shell/CxAttribution';
import CxGridPopup from '@/app/detailsPanel/common/CxGridPopup';

export default {
  name: 'CxHome',
  mixins: [CxLicenceMixin],
  components: {
    CxHeader,
    CxObjectExplorer,
    CxFilterBar,
    CxFilterPanel,
    CxDetailsPanel,
    CxLicencePopup,
    CxMapTooltip,
    CxAlert,
    CxAttribution,
    CxGridPopup,
  },
  computed: {
    ...mapGetters('map', ['hoveredEntity', 'hoverTooltipPosition', 'selectedEntity', 'selectTooltipPosition']),
    ...mapState('alert', ['alert']),

    isAlertOpened: {
      get() {
        if (!this.alert) return false;
        return this.alert.isOpened;
      },
      set() {
        this.hideAlert();
      }
    },
  },
  methods: {
    ...mapActions('shell', ['notifyMapMounted']),
  },
  async mounted() {
    this.notifyMapMounted();
  }
};
</script>

<style lang="less">
@import '../../common';
@import '../../../node_modules/cesium/Build/Cesium/Widgets/widgets.css';

  #map {
    .size(100vw, 100vh);
  }

  .cesium-viewer {
    flex: 0 0 100%;
    position: fixed;
  }

  .cesium-viewer-animationContainer {
    margin-left: @filterMenuWidth;
  }

  .cesium-viewer-timelineContainer {
    margin-left: @filterMenuWidth;
  }

  .cesium-viewer-bottom {
    display: none;
  }

  #hoverTooltip {
    z-index: @mapHoverTooltipZI;
  }

  #selectTooltip {
    z-index: @mapSelectTooltipZI;
  }
</style>
