<template>
  <label class="cx-filter-title" :class="applyClass" @click="onClick">{{ title }}</label>
</template>

<script>
export default {
  name: 'CxFilterTitle',
  props: {
    title: String,
    content: String,
    activeContent: String,
    isTab: Boolean,
    rank: {
      type: Number,
      default: 1
    },
    input: Function
  },
  computed: {
    isActive() {
      return this.isTab ? this.content === this.activeContent : false;
    },
    applyClass() {
      return {
        'cx-filter-title--tab': this.isTab,
        'cx-filter-title--active': this.isActive,
        'cx-filter-title--primary': this.rank === 1,
        'cx-filter-title--secondary': this.rank === 2,
        'cx-filter-title--tertiary': this.rank === 3
      };
    }
  },
  methods: {
    onClick() {
      if (this.isTab) this.input(this.content);
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-filter-title {
  color: @filterTitleColor;
  padding-left: 10px;

  &.cx-filter-title--tab {
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  &.cx-filter-title--active {
    color: @textColor;
  }

  &.cx-filter-title--primary {
    font-size: 20px;
    font-weight: normal;
  }

  &.cx-filter-title--secondary {
    font-size: @h3FontSize;
    font-weight: lighter;
  }

  &.cx-filter-title--tertiary {
    font-size: @majorFontSize;
    font-weight: lighter;
  }
}
</style>